import "./FourthPage.css";
import CompChar from "../Assets/composite_char.mp4";
import PropMove from "../Assets/props_movement.gif";
import Slider from "react-perfect-slider";
import { Translator, Translate } from "react-auto-translate";
const data = {h1: "Check What Our Users Created With Our App"}
function ThirdPage(Languages) {
  const cacheProvider = {
    get: (language, key) =>
      ((JSON.parse(localStorage.getItem("translations")) || {})[key] || {})[
        language
      ],
    set: (language, key, value) => {
      const existing = JSON.parse(localStorage.getItem("translations")) || {
        [key]: {},
      };
      existing[key] = { ...existing[key], [language]: value };
      localStorage.setItem("translations", JSON.stringify(existing));
    },
  };
  return (
    <Translator
      cacheProvider={cacheProvider}
      from={"en"}
      to={Languages.Languages}
      googleApiKey="AIzaSyCGf-6SQug5ldGlhOB-HBnm6x29xGg2Irk"
    >
    <div className="">
      <section className="sec4">
        <div className="FourthPage">
          <div className="slidShowContainer">
            <div className="videoSlider">
              <Slider
                renderControls={(next, previous) => [
                  <button className="PrevButton" onClick={previous}>
                   &#10140;
                  </button>,
                  <button className="NextButton" onClick={next}>
                    &#10140;
                  </button>,
                ]}
                autoplay="false"
                transitionDuration={400}
                autoplayDuration={300000}
              >
                <div className="Videoslide1">
                  <iframe
                    
                    preload='auto'
                    src="https://www.youtube.com/embed/Hk9pFweR59w?autoplay=0&mute=1&preload=auto"
                  ></iframe>
                </div>
                <div className="Videoslide1">
                  <iframe
                    
                    preload='auto'
                    src="https://www.youtube.com/embed/6bWZykzilzU?autoplay=0&mute=1&preload=auto"
                  ></iframe>
                </div>
                <div className="Videoslide1">
                  <iframe
                    
                    src="https://www.youtube.com/embed/DJaPJMkfLS4?autoplay=0&mute=1&preload=auto"
                  ></iframe>
                </div>
                <div className="Videoslide1">
                  <iframe
                    
                    src="https://www.youtube.com/embed/RjQ__DgkAS0?autoplay=0&mute=1&preload=auto"
                  ></iframe>
                </div>
                <div className="Videoslide1">
                  <iframe
                    
                    src="https://www.youtube.com/embed/GUNvFRzTewk?autoplay=0&mute=1&preload=auto"
                  ></iframe>
                </div>
              </Slider>
              <h1><Translate>{data["h1"]}</Translate></h1>
            </div>
          </div>
        </div>
      </section>
    </div>
    </Translator>
  );
}

export default ThirdPage;
