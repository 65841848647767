import "./App.css";
import IntroVideo from "./Assets/IntroVideo.gif";
import Header from "./Components/Header";
import MainPage from "./Components/mainPage";
import SecondPage from "./Components/SecondPage";
import ThirdPage from "./Components/ThirdPage";
import FourthPage from "./Components/FourthPage";
import FifthPage from "./Components/FifthPage";
import SixthPage from "./Components/SixthPage";
import SeventhPage from "./Components/SeventhPage";
import { Translator, Translate } from "react-auto-translate";
import { Fragment, useState } from "react";
import Fade from "react-reveal/Fade";

function App() {
  const [language, setLanguage] = useState("en");
  const cacheProvider = {
    get: (language, key) =>
      ((JSON.parse(localStorage.getItem("translations")) || {})[key] || {})[
        language
      ],
    set: (language, key, value) => {
      const existing = JSON.parse(localStorage.getItem("translations")) || {
        [key]: {},
      };
      existing[key] = { ...existing[key], [language]: value };
      localStorage.setItem("translations", JSON.stringify(existing));
    },
  };
  // render () {
  return (
    <div className="App">
      <Translator
        cacheProvider={cacheProvider}
        from="en"
        to="hi"
        googleApiKey="AIzaSyCGf-6SQug5ldGlhOB-HBnm6x29xGg2Irk"
      >
        <Fragment>
          <div className="container">
              <Header Languages={language} setLanguages={setLanguage} />
            {/* <Fade bottom> */}
              <MainPage Languages={language} />
            {/* </Fade> */}
            <Fade bottom>
              <SecondPage Languages={language} />
            </Fade>
            <Fade bottom>
              <ThirdPage Languages={language} />
            </Fade>
            <Fade bottom>
              <FourthPage Languages={language} />
            </Fade>
            <Fade bottom>
              <FifthPage Languages={language} />
            </Fade>
            <Fade bottom>
              <SixthPage Languages={language} />
            </Fade>
            {/* <Fade bottom> */}
              <SeventhPage Languages={language} />
            {/* </Fade> */}
          </div>
        </Fragment>
      </Translator>
    </div>
  );
}

// }

export default App;
