import "./ThirdPage.css";
import CompChar from "../Assets/composite_char.mp4";
import PropMove from "../Assets/props_movement.gif";
import TransMove from "../Assets/transitions.m4v";
import VfxMove from "../Assets/VFX.m4v";
import Slider from "react-perfect-slider";
import { Translator, Translate } from "react-auto-translate";
const data = {
  sl1p: "Create Your Character Or",
  sl1b: "Select From Our Library",
  sl2p: "Use a Variety of",
  sl2b: "Animated Props",
  sl2p1: "Suitable For The Scene",
  sl3p: "Add",
  sl3b: "Visual Effects",
  sl3p1: "To Your Scenes And Customize Them.",
  sl4p: "Use Different",
  sl4b: "Transitions",
  sl4p1: "Between The Screens"
};
function ThirdPage(Languages) {
  const cacheProvider = {
    get: (language, key) =>
      ((JSON.parse(localStorage.getItem("translations")) || {})[key] || {})[
        language
      ],
    set: (language, key, value) => {
      const existing = JSON.parse(localStorage.getItem("translations")) || {
        [key]: {},
      };
      existing[key] = { ...existing[key], [language]: value };
      localStorage.setItem("translations", JSON.stringify(existing));
    },
  };
  return (
    <Translator
      cacheProvider={cacheProvider}
      from={"en"}
      to={Languages.Languages}
      googleApiKey="AIzaSyCGf-6SQug5ldGlhOB-HBnm6x29xGg2Irk"
    >
      <div className="">
        <section className="section sec3">
          <div className="ThirdPage">
            <div className="slidShowContainer">
              <Slider
                renderControls={(next, previous) => [
                  <button className="PrevButton" onClick={previous}>
                    &#10140;
                  </button>,
                  <button className="NextButton" onClick={next}>
                    &#10140;
                  </button>,
                  
                  
                ]}
                transitionDuration={800}
                autoplayDuration={8000}
              >
                <div className="slide1">
                  <h1>
                    <Translate>{data["sl1p"]}{" "}</Translate>
                    <span style={{ color: "#fd6e43" }}>
                      <Translate>{data["sl1b"]}</Translate>
                    </span>
                    .
                  </h1>
                  <video autoPlay muted>
                    <source src={CompChar} type="video/mp4" />
                  </video>
                </div>

                <div className="slide2">
                  <div>
                    <img src={PropMove} alt="" />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <h1>
                    <Translate>{data["sl2p"]}</Translate>{" "}
                      <span style={{ color: "#fd6e43" }}> <Translate>{data["sl2b"]}{" "}</Translate></span>{" "}
                      <Translate>{data["sl2p1"]}{" "}</Translate>
                    </h1>
                  </div>
                </div>
                <div className="slide3">
                  <h1>
                  <Translate>{data["sl3p"]}{" "}</Translate>
                    <span style={{ color: "#fd6e43" }}><Translate>{data["sl3b"]}</Translate>  </span>
                    <Translate>{data["sl3p1"]}</Translate>
                  </h1>
                  <video
                    disablePictureInPicture
                    autoPlay
                    muted
                    controlsList="nodownload"
                  >
                    <source src={VfxMove} type="video/mp4" />
                  </video>
                </div>
                <div className="slide4">
                  <h1>
                  <Translate>{data["sl4p"]}</Translate>
                    <span style={{ color: "#fd6e43" }}> <Translate>{data["sl4b"]}</Translate> </span>
                    <Translate>{data["sl4p1"]}</Translate>
                  </h1>
                  <video
                    disablePictureInPicture
                    autoPlay
                    muted
                    controlsList="nodownload"
                  >
                    <source src={TransMove} type="video/mp4" />
                  </video>
                </div>
              </Slider>
            </div>
          </div>
        </section>
      </div>
    </Translator>
  );
}

export default ThirdPage;
