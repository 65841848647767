import "./SeventhPage.css";
import {Translator, Translate} from "react-auto-translate";
import Vertical from '../Assets/gif.gif';
import Radium, { StyleRoot } from 'radium';

const data = {
  d1: "What are you waiting for?",
  d2: "Bring your story to the digital world",
  d3: "Tweencraft is owned by Kalpkriti Private Limited. 996 Civil Line Sipri Bazar Jhansi Jhansi UP 284001 IN",
  d4: "Contact us: developer@kalpkriti.com",
};

function SixthPage(Languages) {

  const cacheProvider = {
    get: (language, key) =>
      ((JSON.parse(localStorage.getItem("translations")) || {})[key] || {})[
      language
      ],
    set: (language, key, value) => {
      const existing = JSON.parse(localStorage.getItem("translations")) || {
        [key]: {},
      };
      existing[key] = {...existing[key], [language]: value};
      localStorage.setItem("translations", JSON.stringify(existing));
    },
  };

  const iosDownBn = {
    borderRadius: "15px",
    width: "172px",
    height: "62px",
    '@media (max-width: 480px)': {
      marginTop: "-6px",
      borderRadius: "8px",
      width: "97px",
      height: "42px"

    },
    
  }

  const googleDownBtn = {
    width: "195px",
    height: "85px",
    marginTop: "-11px",
    '@media (max-width: 480px)': {
      // marginTop: "0px",
      width: "110px",
      height: "52px"

    },
  }

  return (
    <Translator
      cacheProvider={cacheProvider}
      from={"en"}
      to={Languages.Languages}
      googleApiKey="AIzaSyCGf-6SQug5ldGlhOB-HBnm6x29xGg2Irk"
    >
      <div className="">
        <section className="section sec7">
          <div className="SeventhPage">
            <div className="svPage" style={{height: "65vh"}}>
            
              <div className="leftSide">
                <img src={Vertical} alt="" />

              </div>
              <div className="RightSide">
                <h1>
                  <Translate>{data["d1"]}</Translate>
                  <div style={{color: "#fd6e43"}}>
                    <Translate>{data["d2"]}</Translate>
                  </div>
                </h1>
                <StyleRoot>
                <ul style={{display: "flex", justifyContent: "left", padding: "40px"}}>
                  <li
                    onClick={() =>
                      window.open(
                        "https://apps.apple.com/ua/app/tweencraft/id1581307535"
                      )
                    }
                  >
                    <img
                      src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1276560000&h=7e7b68fad19738b5649a1bfb78ff46e9"
                      alt="Download on the App Store"
                      style={iosDownBn}
                    />
                  </li>
                  <li
                    onClick={() =>
                      window.open(
                        "https://play.google.com/store/apps/details?id=com.kk.chimpoon&hl=en_IN&gl=US"
                      )
                    }
                  >
                    <img
                      src="https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png"
                      alt="Get it on Google Play"
                      style={googleDownBtn}
                    />
                  </li>
                </ul>
                </StyleRoot>
              </div>
            </div>
            <div id="contact_us" style={{display: "flex", justifyContent: "space-between", alignItems: "center", height: "100% !important"}}>
              <div className="leftSide" style={{width: "50%", display: "flex", alignItems: "center", justifyContent: "left", height: "min-content", padding: "0"}}>
                <p style={{fontSize: "1.25em"}}>
                  {" "}
                  <span>&#169;</span> <Translate>{data["d3"]}</Translate>
                </p>
              </div>
              <div className="RightSide" style={{width: "50%", display: "flex", alignItems: "center", justifyContent: "right", height: "min-content"}}>
                <p style={{fontSize: "1.25em"}}>
                  <span></span><Translate>{data["d4"]}</Translate>
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Translator>
  );
}

export default SixthPage;
