import "./SecondPage.css";
import TomBody from "../Assets/tom_body_n.png";
import TomExpr from "../Assets/character_expression(02).gif";
import TomMove from "../Assets/body_movement(03).gif";
import TomRec from "../Assets/rec(04).gif";
import { useState, useEffect, useRef } from "react";
import { Translator, Translate } from "react-auto-translate";

const Paragraph = [
  { 1: { head: "", para: "" } },
  {
    2: {
      head: "Create smooth Animations with ease",
      para: "Make smooth animations without any prior understanding of animating tools.",
      img: TomBody,
    },
  },
  {
    3: {
      head: "Convey character's emotions through expressions",
      para: "Make characters express themeselves by built-in expressions and actions",
      img: TomExpr,
    },
  },
  {
    4: {
      head: "Bring your character to life with your fingertip.",
      para: "Tweencraft allows you to change a character's action based on your finger gesture.",
      img: TomMove,
    },
  },
  {
    5: {
      head: "Record dialogues with a single tap",
      para: "Just record dialogue and we sync it with the character's lip movement.",
      img: TomRec,
    },
  },
  { 6: {} },
];

const delay = 5000;

function SecondPage(Languages) {
  const [newParagraph, setNewParagraph] = useState({});
  const [newBtnBorder, setNewBtnBorder] = useState({
    1: { borderColor: "grey", fontWeight: "unset", color: "grey" },
    2: { borderColor: "grey", fontWeight: "unset", color: "grey" },
    3: { borderColor: "grey", fontWeight: "unset", color: "grey" },
    4: { borderColor: "grey", fontWeight: "unset", color: "grey" },
  });
  const [index, setIndex] = useState(0);
  const timeoutRef = useRef(null);

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }
  const cacheProvider = {
    get: (language, key) =>
      ((JSON.parse(localStorage.getItem("translations")) || {})[key] || {})[
        language
      ],
    set: (language, key, value) => {
      const existing = JSON.parse(localStorage.getItem("translations")) || {
        [key]: {},
      };
      existing[key] = { ...existing[key], [language]: value };
      localStorage.setItem("translations", JSON.stringify(existing));
    },
  };
  useEffect(() => {
    if (index <= 4) {
      resetTimeout();
      timeoutRef.current = setTimeout(
        () =>
          setIndex((prevIndex) =>
            prevIndex === Paragraph.length - 1 ? 0 : prevIndex + 1
          ),
        delay
      );
      const clrObj = {
        1: { borderColor: "grey", fontWeight: "unset", color: "grey" },
        2: { borderColor: "grey", fontWeight: "unset", color: "grey" },
        3: { borderColor: "grey", fontWeight: "unset", color: "grey" },
        4: { borderColor: "grey", fontWeight: "unset", color: "grey" },
      };
      for (let i = 1; i < 5; i++) {
        if (i == index) {
          clrObj[i]["borderColor"] = "#fd6e43";
          clrObj[i]["fontWeight"] = "bold";
          clrObj[i]["color"] = "black";
        } else {
          clrObj[i]["borderColor"] = "grey";
          clrObj[i]["fontWeight"] = "unset";
          clrObj[i]["color"] = "grey";
        }
      }
      // setNewBtnBorder
      setNewBtnBorder(clrObj);
      setNewParagraph(Paragraph[index][index + 1]);

      return () => {
        resetTimeout();
      };
    }
  }, [index]);
  const setBtnIndex = (value) => {
    setIndex(value);
  };


  return (
    <Translator
      cacheProvider={cacheProvider}
      from={"en"}
      to={Languages.Languages}
      googleApiKey="AIzaSyCGf-6SQug5ldGlhOB-HBnm6x29xGg2Irk"
    >
    <div className="">
      <section className="section sec2">
        <div className="SecondPage">
          <div className="SecLeft">
            <div>
              <img src={newParagraph["img"]} alt="" />
            </div>
          </div>
          <div className="SecRight">
            <div className="content">
              <h1><Translate>{newParagraph["head"]}</Translate></h1>
              <p><Translate>{newParagraph["para"]}</Translate></p>
            </div>
            <div className="button">
              <button
                style={{
                  borderColor: newBtnBorder["1"]["borderColor"],
                  fontWeight: newBtnBorder["1"]["fontWeight"],
                  fontSize: "16px",
                  color: newBtnBorder["1"]["color"],
                }}
                onClick={(e) => {
                  setBtnIndex(1);
                }}
              >
                <Translate>1</Translate>
              </button>
              <button
                style={{
                  borderColor: newBtnBorder["2"]["borderColor"],
                  fontWeight: newBtnBorder["2"]["fontWeight"],
                  fontSize: "16px",
                  color: newBtnBorder["2"]["color"],
                }}
                onClick={(e) => {
                  setBtnIndex(2);
                }}
              >
                <Translate>2</Translate>
              </button>
              <button
                style={{
                  borderColor: newBtnBorder["3"]["borderColor"],
                  fontWeight: newBtnBorder["3"]["fontWeight"],
                  fontSize: "16px",
                  color: newBtnBorder["3"]["color"],
                }}
                onClick={(e) => {
                  setBtnIndex(3);
                }}
              >
                <Translate>3</Translate>
              </button>
              <button
                style={{
                  borderColor: newBtnBorder["4"]["borderColor"],
                  fontWeight: newBtnBorder["4"]["fontWeight"],
                  fontSize: "16px",
                  color: newBtnBorder["4"]["color"],
                }}
                onClick={(e) => {
                  setBtnIndex(4);
                }}
              >
                <Translate>4</Translate>
              </button>
            </div>
          </div>
        </div>
      </section>
    </div>
    </Translator>
  );
}

export default SecondPage;
