import "./Header.css";
import Logo from "../Assets/icon.svg";
import React, { useState, useEffect } from "react";
import Popup from "./Popup";
import { Translator, Translate } from "react-auto-translate";

const data = {
  privacy_policy: "Privacy Policy",
  h1: "Tweencraft",
  en: "English",
  ja: "Japanese",
  pt: "Portuguese",
  es: "Spanish",
  hi: "Hindi",
  ko: "Korean",
  ru: "Russian",
  tr: "Turkish",
  contact: "Contact us",
  download: "Download the App",
};

function Header(Languages, setLanguages) {
  const [isOpen, setIsOpen] = useState(false);

  let onChange = (e) => {
    let newValue = e.target.value;
    Languages.setLanguages(newValue);
    setIsOpen(false)
  };

  const cacheProvider = {
    get: (language, key) =>
      ((JSON.parse(localStorage.getItem("translations")) || {})[key] || {})[
        language
      ],
    set: (language, key, value) => {
      const existing = JSON.parse(localStorage.getItem("translations")) || {
        [key]: {},
      };
      existing[key] = { ...existing[key], [language]: value };
      localStorage.setItem("translations", JSON.stringify(existing));
    },
  };

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };
  function handleScroll() {
    window.scroll({
      top: document.body.scrollHeight,
      left: 0,
      behavior: "smooth",
    });
    setIsOpen(false)
  }
  function handleScrollTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }

  return (
    <Translator
      cacheProvider={cacheProvider}
      from={"en"}
      to={Languages.Languages}
      googleApiKey="AIzaSyCGf-6SQug5ldGlhOB-HBnm6x29xGg2Irk"
    >
      <header className="header">
        <div className="headerLeft">
          <div className="logo" onClick={handleScrollTop}>
            <img src={Logo} />
          </div>
          <h1 onClick={handleScrollTop}>
            <Translate>{data["h1"]}</Translate>
          </h1>
        </div>
        <div className="headerRight">
          <div className="headerMenuTool">
            <div className="fas fa-bars" onClick={togglePopup}></div>
          </div>

          {isOpen && (
            <Popup
              content={
                <>
                  <div className="popup">
                    <div className="headerLeft">
                      <div className="logo" onClick={handleScrollTop}>
                        <img src={Logo} />
                      </div>
                      <h1 onClick={handleScrollTop}>
                        <Translate>{data["h1"]}</Translate>
                      </h1>
                    </div>
                    <div className="headerContent1">
                      <button
                        className="privacyPolicy"
                        onClick={() =>
                          window.open(
                            "https://kalpkriti.blob.core.windows.net/kalpbucketmumbaiv1/tweencraft_privacy_policy.html"
                          )
                        }
                      >
                        <Translate>{data["privacy_policy"]}</Translate>
                      </button>
                      <select value={Languages.Languages} onChange={onChange}>
                        <option key={"en"} value={"en"}>
                          {" "}
                          <Translate>{data["en"]}</Translate>
                        </option>
                        <option key={"ja"} value={"ja"}>
                          <Translate>{data["ja"]}</Translate>
                        </option>
                        <option key={"pt"} value={"pt"}>
                          <Translate>{data["pt"]}</Translate>
                        </option>
                        <option key={"es"} value={"es"}>
                          <Translate>{data["es"]}</Translate>
                        </option>
                        <option key={"hi"} value={"hi"}>
                          {" "}
                          <Translate>{data["hi"]}</Translate>
                        </option>
                        <option key={"ko"} value={"ko"}>
                          <Translate>{data["ko"]}</Translate>
                        </option>
                        <option key={"ru"} value={"ru"}>
                          <Translate>{data["ru"]}</Translate>
                        </option>
                        <option key={"tr"} value={"tr"}>
                          <Translate>{data["tr"]}</Translate>
                        </option>
                      </select>
                      <button className="contactUs" onClick={handleScroll}>
                        <Translate>{data["contact"]}</Translate>
                      </button>
                    </div>
                  </div>
                </>
              }
              handleClose={togglePopup}
            />
          )}

          <div className="headerContent">
            <button
                className="privacyPolicy"
                onClick={() =>
                  window.open(
                    "https://kalpkriti.blob.core.windows.net/kalpbucketmumbaiv1/tweencraft_privacy_policy.html"
                  )
                }
              >
                <Translate>{data["privacy_policy"]}</Translate>
            </button>
            <select value={Languages.Languages} onChange={onChange}>
              <option key={"en"} value={"en"}>
                {" "}
                <Translate>{data["en"]}</Translate>
              </option>
              <option key={"ja"} value={"ja"}>
                <Translate>{data["ja"]}</Translate>
              </option>
              <option key={"pt"} value={"pt"}>
                <Translate>{data["pt"]}</Translate>
              </option>
              <option key={"es"} value={"es"}>
                <Translate>{data["es"]}</Translate>
              </option>
              <option key={"hi"} value={"hi"}>
                {" "}
                <Translate>{data["hi"]}</Translate>
              </option>
              <option key={"ko"} value={"ko"}>
                <Translate>{data["ko"]}</Translate>
              </option>
              <option key={"ru"} value={"ru"}>
                <Translate>{data["ru"]}</Translate>
              </option>
              <option key={"tr"} value={"tr"}>
                <Translate>{data["tr"]}</Translate>
              </option>
            </select>
            <button className="contactUs" onClick={handleScroll}>
              <Translate>{data["contact"]}</Translate>
            </button>
            <button
              className="download"
              onClick={() =>
                window.open(
                  "https://play.google.com/store/apps/details?id=com.kk.chimpoon&hl=en_IN&gl=US"
                )
              }
            >
              <Translate>{data["download"]}</Translate>
            </button>
          </div>
        </div>
      </header>
    </Translator>
  );
}
export default Header;
