import "./SixthPage.css";
import {Translator, Translate} from "react-auto-translate";
import IntroVideo from '../Assets/all_char.png';
const data = {d1: "Join our community", d2: "get your daily dose of motivation to create."}
function SixthPage(Languages) {
  const cacheProvider = {
    get: (language, key) =>
      ((JSON.parse(localStorage.getItem("translations")) || {})[key] || {})[
      language
      ],
    set: (language, key, value) => {
      const existing = JSON.parse(localStorage.getItem("translations")) || {
        [key]: {},
      };
      existing[key] = {...existing[key], [language]: value};
      localStorage.setItem("translations", JSON.stringify(existing));
    },
  };
  return (
    <Translator
      cacheProvider={cacheProvider}
      from={"en"}
      to={Languages.Languages}
      googleApiKey="AIzaSyCGf-6SQug5ldGlhOB-HBnm6x29xGg2Irk"
    >
      <div className="">
        <section className="sec6">
          <div className="SixthPage">
            <div className="leftSide">
              <img src={IntroVideo} alt="" />
            </div>
            <div className="RightSide">
              <h1>
                <div style={{color: "#fd6e43"}}><Translate>{data["d1"]}</Translate></div>
                <Translate>{data["d2"]}</Translate>
              </h1>
              <ul>
                <li
                  className="fa fa-facebook-f"
                  onClick={() =>
                    window.open("https://www.facebook.com/tweencraftapp")
                  }
                ></li>
                <li
                  className="fa fa-instagram"
                  onClick={() =>
                    window.open(
                      "https://www.instagram.com/tweencraftglobal/?__coig_restricted=1"
                    )
                  }
                ></li>
                <li
                  className="fa fa-youtube-play"
                  onClick={() =>
                    window.open(
                      "https://www.youtube.com/channel/UCPd9B4KUGCrYREywRBLQ3yg"
                    )
                  }
                ></li>
                <li
                  className="fab fa-discord"
                  onClick={() =>
                    window.open(
                      "https://apps.apple.com/ua/app/tweencraft/id1581307535"
                    )
                  }
                ></li>
                <li
                  className="fa fa-telegram"
                  onClick={() => window.open("https://t.me/tweencraftofficial")}
                ></li>
              </ul>
            </div>
          </div>
        </section>
      </div>
    </Translator>
  );
}

export default SixthPage;
