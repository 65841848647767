import "./FifthPage.css";
import {Translator, Translate} from "react-auto-translate";
import React from "react";
import Slider from "react-slick";
import MagicSliderDots from "react-magic-slider-dots";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-magic-slider-dots/dist/magic-dots.css";

const data = {
  new: "New",
  h11: "Challenges",
  h12: "Advanced voice settings",
  h13: "Use in Local language",
  p1: "You can participate in the ongoing competition by following the rules, create amazing videos and win exciting prizes .",
  p2: "Adjust the pitch and tempo of the character’s voice or use your real voice.",
  p3: "Tweencraft is available in eight different languages. You can switch between the languages at any time.",
};


const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  centerMode: false,
  appendDots: dots => (
    <MagicSliderDots dots={dots} numDotsToShow={3} dotWidth={30} />
  )
};

function FifthPage(Languages) {
  const cacheProvider = {
    get: (language, key) =>
      ((JSON.parse(localStorage.getItem("translations")) || {})[key] || {})[
      language
      ],
    set: (language, key, value) => {
      const existing = JSON.parse(localStorage.getItem("translations")) || {
        [key]: {},
      };
      existing[key] = {...existing[key], [language]: value};
      localStorage.setItem("translations", JSON.stringify(existing));
    },
  }

  return (
    <Translator
      cacheProvider={cacheProvider}
      from={"en"}
      to={Languages.Languages}
      googleApiKey="AIzaSyCGf-6SQug5ldGlhOB-HBnm6x29xGg2Irk"
    >
      <div className="">
        <section className="section sec5">
          <div className="FifthPage">
            <div className="cards desktopView">
              <div className="hcard hc0">

                <div className="cardView">
                  <div className="cardTopPos">
                    <div className="cardNew">
                      <Translate>{data["new"]}</Translate>
                    </div>
                  </div>
                  <div style={{padding: "30px 32px 5px"}}>
                    <div className="fas fa-trophy"></div>
                    <h1>
                      {" "}
                      <Translate>{data["h11"]}</Translate>{" "}
                    </h1>
                    <p><Translate>{data["p1"]}</Translate></p>
                  </div>
                </div>
              </div>

              <div className="hcard hc1" style={{}}>

                <div className="cardView">
                  <div className="cardTopPos">
                    <div className="cardNew"><Translate>{data["new"]}</Translate> </div>
                  </div>
                  <div style={{padding: "30px 32px 30px"}}>
                    <div className="fa fa-microphone"></div>
                    <h1> <Translate>{data["h12"]}</Translate> </h1>
                    <p>
                      <Translate>{data["p2"]}</Translate>
                    </p>
                  </div>
                </div>
              </div>

              <div className="hcard hc2" style={{}}>

                <div className="cardView">
                  <div className="cardTopPos">
                    <div className="cardNew"><Translate>{data["new"]}</Translate></div>
                  </div>
                  <div style={{padding: "30px 32px 5px"}}>
                    <div className="fa fa-globe"></div>
                    <h1> <Translate>{data["h13"]}</Translate> </h1>
                    <p>
                      <Translate>{data["p3"]}</Translate>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="cards mobileView">

              <Slider {...settings}>
                <div className="hcard hc0">

                  <div className="cardView">
                    <div className="cardTopPos">
                      <div className="cardNew">
                        <Translate>{data["new"]}</Translate>
                      </div>
                    </div>
                    <div style={{padding: "30px 32px 5px"}}>
                      <div className="fas fa-trophy"></div>
                      <h1>
                        {" "}
                        <Translate>{data["h11"]}</Translate>{" "}
                      </h1>
                      <p><Translate>{data["p1"]}</Translate></p>
                    </div>
                  </div>
                </div>

                <div className="hcard hc1" style={{}}>

                  <div className="cardView">
                    <div className="cardTopPos">
                      <div className="cardNew"><Translate>{data["new"]}</Translate> </div>
                    </div>
                    <div style={{padding: "30px 32px 10px"}}>
                      <div className="fa fa-microphone"></div>
                      <h1> <Translate>{data["h12"]}</Translate> </h1>
                      <p>
                        <Translate>{data["p2"]}</Translate>
                      </p>
                    </div>
                  </div>
                </div>

                <div className="hcard hc2" style={{}}>

                  <div className="cardView">
                    <div className="cardTopPos">
                      <div className="cardNew"><Translate>{data["new"]}</Translate></div>
                    </div>
                    <div style={{padding: "30px 32px 10px"}}>
                      <div className="fa fa-globe"></div>
                      <h1> <Translate>{data["h13"]}</Translate> </h1>
                      <p>
                        <Translate>{data["p3"]}</Translate>
                      </p>
                    </div>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </section>
      </div>
    </Translator>
  );
}

export default FifthPage;
